















































import { ObjectListObject } from '@knitiv/api-client-javascript'
import Vue, { PropType } from 'vue'
import { DataTableHeader } from 'vuetify'
import { fetchReports } from '@/utils/dataexchange'

interface Data {
  json: any;
  headers: DataTableHeader[];
}

export default Vue.extend({
  name: 'Report',
  props: {
    resolve: {
      type: Function as PropType<(arg: any) => any>,
      required: true,
    },
    reject: {
      type: Function as PropType<(arg: any) => any>,
      required: true,
    },
    // DATAEXCH_EXEC
    item: {
      type: Object as PropType<ObjectListObject>,
      required: true,
    },
  },
  data(): Data {
    return {
      json: {},
      headers: [
        {
          text: 'Ligne',
          value: 'line',
        },
        {
          text: 'Message',
          value: 'message',
        },
      ],
    }
  },
  async mounted() {
    const kjson = await fetchReports(this.item.kid)

    this.json = kjson
  },
})
