import { SharedConstants } from '@knitiv/api-client-javascript'
import { api } from './api'

interface Result {
  "Critical Error": string;
  "Nom classe maître": string;
  "Nom technique classe maître": string;
  created: number;
  error: number;
  ignored: number;
  updated: number;
  errors: {
    line: string;
    message: string;
  }[]
}

export const fetchReports = async (itemKid: string) => {
  const obj = await api().queryExec({
    select: [
      {
        inherits_extended: -1,
        node: 'ISA',
        kid: 'K_NODE;DATAEXCH_REPORT',
        op: 'detail',
        type: SharedConstants.types.STRING,
        path: [
          {
            kid: 'K_NODE;DATAEXCH_EXEC',
            // @ts-ignore
            sens: null,
          },
          {
            kid: 'K_NODE;DATAEXCH_REPORT',
            // @ts-ignore
            sens: 'UP',
          },
        ],
      },
    ],
    from: [
      {
        node: 'K_NODE;DATAEXCH_EXEC',
        type: SharedConstants.types.STRING,
        inherits_extended: -1,
      },
    ],
    where: {
      condition: 'AND',
      rules: [
        {
          condition: 'NONE',
          id: 'K_NODE;DATAEXCH_EXEC',
          field: 'K_NODE;DATAEXCH_EXEC',
          having: null,
          operator: 'equal_kid',
          type: 'K_NODE;STRING',
          value: {
            // @ts-ignore
            kid: itemKid,
          },
        },
      ],
    },
  })

  const result = obj.result.rows[0][0][0]

  const kjson = JSON.parse(result.kjson) as Result

  return kjson
}
