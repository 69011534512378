































































































































import Vue from 'vue'
import {
  AutocompleteItem,
  DataExchangeStatuses,
  ObjectCreateOutput,
  ObjectListObject,
  SharedConstants,
} from '@knitiv/api-client-javascript'
import ModalReport from '@/components/importfile/viewer/modal-report.vue'
import {
  IntegrationProgressTypes, isIntegrationEndEvent, isIntegrationInitEvent, isIntegrationStartEvent,
} from './integrations/models/events'
import ClassViewerList from '@/components/importfile/class-viewer-list.vue'
import NewIntegrationModal from '@/components/importfile/modal-new-integration.vue'
import { api, ws } from '@/utils/api'
import modalIntegrationLog from '@/components/importfile/modal-integration-log.vue'
import { WithRefs } from '@/models/vue'
import { fetchReports } from '@/utils/dataexchange'
import { entries } from '@/utils/entries'
import { downloadRaw } from '@/utils/download'

type enhancedObject = ObjectListObject & { kid: string }
type ProcessedObj = ObjectListObject & { statusStatus: string }

type Refs = {
  list: InstanceType<typeof ClassViewerList>
}

export default (Vue as WithRefs<Refs>).extend({
  name: 'Integrations',
  components: {
    ClassViewerList,
  },
  data() {
    return {
      integration: SharedConstants.nodes.DATAEXCH_EXEC,
      selectedItem: null,

      dataSources: [] as enhancedObject[],

      progress: {},

      toImportStatus: SharedConstants.nodes.DATAEXCH_STATUS_TO_IMPORT,
      importingStatus: SharedConstants.nodes.DATAEXCH_STATUS_IMPORTING,
      importedStatus: SharedConstants.nodes.DATAEXCH_STATUS_IMPORTED,

      subscriptions: [] as string[],
    }
  },

  computed: {
    dbId() {
      console.log('this.$accessor.user', this.$accessor.user)
      return `${this.$accessor.user?.db.kid}`
    },
  },

  methods: {
    async downloadReport(item: ObjectListObject) {
      const kjson = await fetchReports(item.kid)

      const result = []

      entries(kjson).forEach(([key, value]) => {
        if (key !== 'errors') {
          result.push(`${key}: ${value }`)
        }
      })

      if (kjson.errors) {
        result.push(`\nErreurs: \n`)
        kjson.errors.forEach(value => {
          result.push(`Ligne ${value.line}: ${value.message}`)
        })
      }

      const response = result.join('\n')

      return downloadRaw(`${item.name}.txt`, 'text/text', response)
    },
    canSubscribe(status: string) {
      return status === this.toImportStatus || status === this.importingStatus
    },
    async processItem(item: ObjectListObject): Promise<ProcessedObj> {
      console.log('item', item)
      const obj = await api().objectInfo({ kid: item.kid })

      const list = obj.link.list.from[api().constants.relations.COMPOSED_OF]?.list ?? {}
      const values = Object.values(list)

      const statusNode = values.find((val) => val.isa === SharedConstants.nodes.DATAEXCH_STATUS)

      if (statusNode) {
        const result: ProcessedObj = {
          ...item,
          statusStatus: statusNode.objid,
        }
        console.log('result', result)
        return result
      }
      throw new Error('No status found!')
    },
    async onAdd() {
      const data = await this.$dialog.open<ObjectCreateOutput>(NewIntegrationModal)
      if (data && data !== 'close') {
        const newId = data._NR_replace['K_NODE;_NR_INTEGRATION']
        this.$refs.list.refresh()

        await this.subscribe(newId)
      }
    },

    async showReport(item: ObjectListObject) {
      await this.$dialog.open(ModalReport, {
        maxWidth: 800,
        scrollable: true,
        component: {
          item,
        },
      })
    },

    hasAlreadySubscribed(kid: string) {
      return this.subscriptions.includes(kid)
    },
    async onDataLoaded(dataSources: { kid: string, statusStatus: DataExchangeStatuses }[]) {
      const promises: (() => Promise<any>)[] = []
      dataSources.forEach((source) => {
        promises.push(async () => {
          if (
            [
              SharedConstants.nodes.DATAEXCH_STATUS_TO_IMPORT,
              SharedConstants.nodes.DATAEXCH_STATUS_IMPORTING,
              // @ts-ignore
            ].includes(source.statusStatus)) {
            await this.subscribe(source.kid)
          }
        })
      })

      await Promise.all(promises.map((x) => x()))
    },

    getPath(kid: string) {
      return `${this.dbId}/autoExchange/${kid}`
    },

    async subscribe(kid: string) {
      console.log('kid', kid)
      if (this.hasAlreadySubscribed(kid)) {
        return
      }
      const manager = await ws()
      const path = this.getPath(kid)

      const room = manager.createRoom<IntegrationProgressTypes>(path)

      room.onMessage(async (event) => {
        if (isIntegrationInitEvent(event)) {
          console.log('init')
        } else if (isIntegrationStartEvent(event)) {
          console.log('start')
          // eslint-disable-next-line no-case-declarations
          const mStart = `L'intégration de ${kid} a débutée`
          try {
            await this.$notification.show(mStart)
          } catch (e) {
            console.error('Error showing notification', e)
          }

          this.$toast.success(mStart)
          this.$refs.list.refresh()
        } else if (isIntegrationEndEvent(event)) {
          console.log('end')
          // eslint-disable-next-line no-case-declarations
          const mEnd = `L'intégration de ${kid} est terminée`
          try {
            await this.$notification.show(mEnd)
          } catch (e) {
            console.error('Error showing notification', e)
          }

          this.$toast.success(mEnd)
          this.$refs.list.refresh()
        }
      })

      room.subscribe()

      this.subscriptions.push(kid)
    },

    async showStream(item: AutocompleteItem) {
      this.subscribe(item.kid)

      // await api().objectInfo({ kid: item.kid })

      await this.$dialog.open(modalIntegrationLog, {
        component: {
          kid: item.kid,
          path: this.getPath(item.kid),
        },
      })
    },
  },
})
