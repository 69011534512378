



























































import Vue, { PropType } from 'vue'
import {
  ObjectCreateItem, AutocompleteItem, SharedConstants, ObjectCreateLink, ObjectCreateObject, ObjectCreateOutput,
} from '@knitiv/api-client-javascript'
import dayjs from 'dayjs'
import MfInput from '@/components/mf/mf.vue'
import { api } from '@/utils/api'

interface Data {
  fileOrder: string;
  file: AutocompleteItem[];

  templateOrder: string;
  template: AutocompleteItem[];

  statusOrder: string;
  status: AutocompleteItem[];

  isCreating: boolean;

  denyList: string[]
}

export default Vue.extend({
  components: {
    MfInput,
  },
  props: {
    resolve: {
      type: Function as PropType<(arg: any) => any>,
      required: true,
    },
    reject: {
      type: Function as PropType<(arg: any) => any>,
      required: true,
    },
  },

  data(): Data {
    return {
      isCreating: false,

      status: [],
      statusOrder: SharedConstants.nodes.DATAEXCH_STATUS,

      file: [],
      fileOrder: SharedConstants.nodes.DATAEXCH_FILE,

      template: [],
      templateOrder: SharedConstants.nodes.DATAEXCH_TEMPLATE,

      denyList: [
        SharedConstants.nodes.DATAEXCH_STATUS_IMPORTED,
        SharedConstants.nodes.DATAEXCH_STATUS_IMPORTING,
      ],
    }
  },

  methods: {
    resetModal() {
      //
    },

    close(data: ObjectCreateOutput) {
      this.resetModal()
      this.resolve(data)
    },

    checkErrors() {
      throw new Error('Template contains errors')
    },

    async create() {
      /* try {
        await this.checkErrors()
      } catch (e) {
        this.$toast.error('Le template contient des erreurs. Veuillez le corriger avant de lancer une intégration')
        console.error(e)
        return
      } */

      this.isCreating = true

      const composedOfs: ObjectCreateLink[] = [
        {
          class_kid: SharedConstants.nodes.DATAEXCH_STATUS,
          linker_kid: '0',
          objnum2: this.status[0].kid,
        },
      ]

      const NR_DATE = 'K_NODE;_NR_DATE'
      const NR_INTEGRATION = 'K_NODE;_NR_INTEGRATION'
      const NR_PROCESS = 'K_NODE;_NR_PROGRESS'

      if (api().backendEngine > 3) {
      // @ŧs-ignore
        composedOfs.push({
          class_kid: SharedConstants.types.DATE,
          linker_kid: SharedConstants.nodes.ASK,
          objnum2: NR_DATE,
        })
      }

      composedOfs.push({
        class_kid: SharedConstants.nodes.DATAEXCH_PROCESS,
        linker_kid: '0',
        objnum2: NR_PROCESS,
      })

      const objectList: Record<string, ObjectCreateObject> = {
        [NR_INTEGRATION]: {
          isa: SharedConstants.nodes.DATAEXCH_EXEC,
          LINK_LIST: {
            [api().constants.relations.COMPOSED_OF]: composedOfs,
          },
        },
      }

      if (api().backendEngine > 3) {
        objectList[NR_DATE] = {
          isa: SharedConstants.types.DATE,
          REPRE_LIST: [
            {
              lang: SharedConstants.representations.UNIVERSEL,
              name: dayjs().format(SharedConstants.date.api),
            },
          ],
        }
      }

      objectList[NR_PROCESS] = {
        isa: SharedConstants.nodes.DATAEXCH_PROCESS,
        LINK_LIST: {
          [api().constants.relations.COMPOSED_OF]: [
            {
              class_kid: SharedConstants.nodes.DATAEXCH_FILE,
              linker_kid: '0',
              objnum2: this.file[0].kid,
            },
            {
              class_kid: SharedConstants.nodes.DATAEXCH_TEMPLATE,
              linker_kid: '0',
              objnum2: this.template[0].kid,
            },
          ],
        },
      }

      const item: ObjectCreateItem = {
        target_objnum: NR_INTEGRATION,
        OBJECT_LIST: objectList,
      }

      try {
        const result = await api().objectSet({
          items: [item],
        })
        this.close(result)
      } catch (e) {
        this.$toast.error(`Impossible de créer la demande d'intégration\n${e.message}`)
        console.error('e', e)
        this.resolve(null)
      } finally {
        this.isCreating = false
      }
    },
  },
})
