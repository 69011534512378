var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"full-height"},[_c('class-viewer-list',{ref:"list",staticClass:"full-width",attrs:{"class-id":_vm.integration,"confirm-on-delete":"","delete-action":"","process-item":_vm.processItem},on:{"loaded":_vm.onDataLoaded},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var item = ref.item;
return [(_vm.canSubscribe(item.statusStatus) && !_vm.hasAlreadySubscribed(item.kid))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.subscribe(item.kid)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-sync-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v("S'abonner aux logs")])]):_vm._e(),(_vm.subscriptions.includes(item.kid))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"green"}},on),[_vm._v(" mdi-sync-circle ")])]}}],null,true)},[_c('span',[_vm._v("Abonné aux logs")])]):_vm._e(),(_vm.canSubscribe(item.statusStatus))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.showStream(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-text-box ")])],1)]}}],null,true)},[_c('span',[_vm._v("Afficher les logs")])]):_vm._e(),(item.statusStatus === _vm.importedStatus)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.downloadReport(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-download ")])],1)]}}],null,true)},[_c('span',[_vm._v("Télécharger le rapport")])]):_vm._e(),(item.statusStatus === _vm.importedStatus)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.showReport(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-math-log ")])],1)]}}],null,true)},[_c('span',[_vm._v("Afficher le rapport")])]):_vm._e()]}},{key:"header-buttons",fn:function(){return [_c('v-btn',{attrs:{"text":""},on:{"click":_vm.onAdd}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Ajouter ")],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }