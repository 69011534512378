





































import Vue, { PropType } from 'vue'
import { BaseIntegrationProgressEvent, IntegrationProgressTypes, isIntegrationProgressEvent } from '@/pages/importfile/dashboard/integrations/models/events'
import { ws } from '@/utils/api'

export default Vue.extend({
  props: {
    resolve: {
      type: Function as PropType<(arg: any) => any>,
      required: true,
    },
    reject: {
      type: Function as PropType<(arg: any) => any>,
      required: true,
    },
    kid: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      lines: [] as BaseIntegrationProgressEvent['data'][],
    }
  },
  async mounted() {
    const manager = await ws()
    const room = manager.createRoom<IntegrationProgressTypes>(this.path)
    room
      .onMessage((event) => {
        if (isIntegrationProgressEvent(event)) {
          this.lines.push(event.payload.data)
        }
      })
      .subscribe()
  },

})
