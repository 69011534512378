



























































































import Vue, { PropType } from 'vue'
import { map } from 'lodash'
import { ObjectListObject, SharedConstants } from '@knitiv/api-client-javascript'
import { Promisable } from 'type-fest'
import { api } from '@/utils/api'

type enhancedObject = ObjectListObject & { kid: string }

export default Vue.extend({
  name: 'ClassViewerlist',
  props: {
    classId: {
      type: String,
      required: true,
    },

    deleteAction: {
      type: Boolean,
      default: false,
    },

    confirmOnDelete: {
      type: Boolean,
      default: true,
    },

    title: {
      type: String,
      default: '',
    },

    selected: {
      type: String,
      default: '',
    },

    processItem: {
      type: Function as PropType<(state: any) => Promisable<any>>,
      default: (state) => state,
    },
  },
  data() {
    return {
      itemNumber: -1,
      isLoading: false,
      headers: [
        {
          text: 'Nom',
          value: 'name',
        },
        // { text: 'KID', value: 'kid' },
        { text: 'Actions', value: 'action', align: 'right' },
      ],

      dataSources: [] as any[],
      footerProps: {
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 25, 50, 100],
      },
      options: {
        itemsPerPage: 10,
        page: 1,
      },
    }
  },
  computed: {
    hasNameSlot() {
      return !!this.$scopedSlots.name
    },
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.refresh()
      },
    },
  },

  async mounted() {
    await this.refresh()
  },
  methods: {
    async refresh(): Promise<void> {
      this.isLoading = true
      const initialResponse = await api().objectList({
        classKid: this.classId,
        condition: [],
        options: {
          packet_index: 0,
          packet_size: 1,
        },
      })
      this.itemNumber = initialResponse.nb

      const response = await api().objectList({
        classKid: this.classId,
        condition: [],
        options: {
          packet_index: (this.options.page - 1) * this.options.itemsPerPage,
          packet_size: this.options.itemsPerPage,
        },
      })

      this.dataSources = map(response.list, (object, kid) => ({
        ...object,
        kid,
        name: object.repre_all[SharedConstants.representations.UNIVERSEL].name,
      }))

      if (this.processItem) {
        const results: any[] = []
        for await (const item of this.dataSources) {
          const result = await this.processItem(item)
          results.push(result)
        }
        this.dataSources = results
      }

      this.isLoading = false

      this.$emit('loaded', this.dataSources)
    },
    async deleteItem(item: enhancedObject) {
      if (this.confirmOnDelete) {
        const answer = await this.$dialog.confirm(
          `Êtes-vous sûr de vouloir supprimer "${item.name}" ?`,
          'Cette suppression est irréversible. Faites bien attention !',
          {
            cancel: {
              label: 'Annuler',
              props: {
                color: 'default',
              },
            },
            delete: {
              label: 'Supprimer',
              props: {
                color: 'error',
              },
            },
          },
        )
        // const answer = await this.$dialog.confirm('Title', 'Content', 'ButtonOK', 'ButtonCancel')
        // const answer = await this.$dialog.prompt('Title', 'Content', 'ButtonOK', 'ButtonCancel')
        // const answer = await this.$dialog.open(Alert, {
        //   maxWidth: 500
        // })
        if (answer === 'delete') {
          await api().objectDelete({
            kid: item.kid,
          })
          this.$emit('item-deleted', item.kid)
          await this.refresh()
        } else {
          console.error('denied...')
        }
      }
    },
  },
})
